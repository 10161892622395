import { useContext, useEffect, useState } from "react";
import YouTube, { YouTubeEvent, YouTubePlayer } from "react-youtube";
import styled from "styled-components";
import { searchSpotifyTracks } from "../api/Spotify.api";
import { SpotifyTrack } from "../models/SpotifyTrack.model";
import { Challenge } from "../models/Challenge.model";
import { getDateString } from "../models/Utils";
import { getChallenge } from "../api/Challenge.api";
import { User } from "../models/User.model";
import { getUsersByDate, updateUser } from "../api/User.api";
import { ActiveUserContext } from "../utils/contexts";
import {
  Button,
  GuessResultMarker,
  LoadingTitle,
  SEGMENT_TOTALS,
  SegmentContainer,
  SegmentFull,
  SegmentPlaying,
  SegmentRevealed,
  SkippedGuess,
  Tab,
  TitleText,
  TrackContainer,
  TrackImage,
  TrackTitle,
  YouTubeBlocker,
  YouTubeContainer,
} from "./Common";
import Leaderboard from "./Leaderboard";
import AnswerTrack from "./AnswerTrack";
import MiniLeaderboard from "./MiniLeaderboard";
import Changelog from "./Changelog";

interface HomePageProps {
  navigate: (t: Tab) => void;
}

const SelectedTrackTitle = styled(TrackTitle)`
  margin-right: 10px;
`;

const SearchResultContainer = styled(TrackContainer)`
  height: 64px;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.HIGHLIGHT};
  }
`;

const SearchResultImage = styled(TrackImage)`
  width: 64px;
  height: 64px;
`;

const SearchResultText = styled(TrackTitle)`
  margin-left: 74px;
  margin-right: 10px;
  line-height: 32px;
`;

const GuessSlot = styled(TrackContainer)`
  background-color: ${(props) => props.theme.DISABLED};
`;

const renderUnusedGuessSlots = (slots: number) => {
  const result: JSX.Element[] = [];
  for (let i = 0; i < slots; i++) {
    result.push(<GuessSlot />);
  }
  return result;
};

const SearchContainer = styled.div``;

const SearchInput = styled.input`
  font-family: monospace;
  font-size: 18px;
  border: 4px solid ${(props) => props.theme.SECONDARY};
  width: 200px;
  margin-left: 10px;
  border-radius: 5px;
  padding: 4px;
  color: ${(props) => props.theme.TEXT};
  background-color: ${(props) => props.theme.BACKGROUND};

  &:focus {
    outline: 0;
    border: 4px solid ${(props) => props.theme.HIGHLIGHT};
  }
`;

const HomePage = ({ navigate }: HomePageProps) => {
  const userId = useContext(ActiveUserContext);
  const [challengeData, setChallengeData] = useState<Challenge | null>(null);
  const [youtube, setYoutube] = useState<YouTubePlayer | null>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<SpotifyTrack[]>([]);
  const [selectedTrack, setSelectedTrack] = useState<SpotifyTrack | null>(null);
  const [guesses, setGuesses] = useState<(SpotifyTrack | null)[]>([]);
  const [users, setUsers] = useState<User[] | null>(null);
  const [gameOver, setGameOver] = useState<boolean>(false);

  useEffect(() => {
    const today = getDateString(new Date());
    // const today = "2024-05-27";
    getChallenge(today).then((res) => {
      setChallengeData(res);
      getUsersByDate(today).then((users) => {
        setUsers(users);
        const signedInUser = users.find((u) => u.userId === userId);
        if (signedInUser) {
          setGuesses(signedInUser.guesses);
          if (
            signedInUser.guesses.length === 6 ||
            signedInUser.guesses.some((t) => t?.id === res.spotify.id)
          )
            setGameOver(true);
        }
      });
    });
  }, [userId]);

  const onPlayerReady = (event: YouTubeEvent) => {
    setYoutube(event.target);
  };

  const onPlay = () => {
    if (youtube) {
      youtube.playVideo();
    }
  };

  const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchTerm(e.target.value);
  };

  const onSearchKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") onSearch();
  };

  const onSearch = () => {
    if (searchTerm.length > 0) {
      console.log("search:", searchTerm);
      searchSpotifyTracks(searchTerm).then((tracks) => {
        setSelectedTrack(null);
        setSearchResults(tracks);
      });
    }
  };

  const onSelectGuess = (track: SpotifyTrack) => {
    setSelectedTrack(track);
    setSearchResults([]);
  };

  const onSubmitTrack = (track: SpotifyTrack | null) => {
    console.log("submitted", track?.id);

    if (users && challengeData) {
      const signedInUser = users.find((u) => u.userId === userId);

      const userPayload: User = {
        userId: userId,
        date: challengeData.date,
        guesses: signedInUser ? [...signedInUser.guesses, track] : [track],
        correctStreak: signedInUser ? signedInUser.correctStreak : 0,
        participatedStreak: signedInUser ? signedInUser.participatedStreak : 0,
        firstGuessTimestamp: signedInUser
          ? signedInUser.firstGuessTimestamp
          : 0,
      };

      if (userPayload.guesses.length === 1) {
        userPayload.participatedStreak += 1;
        userPayload.firstGuessTimestamp = new Date().getTime();
      }

      if (track?.id === challengeData.spotify.id) {
        userPayload.correctStreak += 1;
      }

      updateUser(userPayload).then(() => {
        const today = getDateString(new Date());
        getUsersByDate(today).then((users) => {
          setUsers(users);
          setSelectedTrack(null);
          const signedInUser = users.find((u) => u.userId === userId);
          if (signedInUser) {
            setGuesses(signedInUser.guesses);
            if (
              signedInUser.guesses.length === 6 ||
              signedInUser.guesses.some(
                (t) => t?.id === challengeData.spotify.id
              )
            )
              setGameOver(true);
          }
        });
      });
    }
  };

  const onSkip = () => {
    onSubmitTrack(null);
  };

  const onStateChange = (e: YouTubeEvent) => {
    if (challengeData && e.data === 1) {
      // 1 == "Playing"
      const duration = gameOver
        ? challengeData.youtube.length
        : SEGMENT_TOTALS[guesses.length];
      setPlaying(true);
      setTimeout(() => {
        youtube.pauseVideo();
        youtube.seekTo(0);
        setPlaying(false);
      }, duration * 1000);
    }
  };

  return (
    <>
      {challengeData && users ? (
        <>
          <YouTubeContainer>
            <YouTube
              videoId={challengeData.youtube.id}
              onReady={onPlayerReady}
              onStateChange={onStateChange}
              opts={{
                height: "70",
                width: "200",
              }}
            />
            <YouTubeBlocker />
            <TitleText>Songs with Associates</TitleText>
          </YouTubeContainer>
          {guesses.map((track) =>
            track ? (
              <TrackContainer>
                <TrackImage src={track.imageUrlSmall} />
                <TrackTitle>{track.name}</TrackTitle>
                <GuessResultMarker
                  correct={track.id === challengeData.spotify.id}
                />
              </TrackContainer>
            ) : (
              <SkippedGuess />
            )
          )}
          {renderUnusedGuessSlots(6 - guesses.length)}
          <SegmentContainer>
            <SegmentFull>
              <SegmentRevealed
                guessesLength={guesses.length}
                gameOver={gameOver}
              >
                <SegmentPlaying
                  guessesLength={guesses.length}
                  playing={playing}
                  gameOver={gameOver}
                  fullLength={challengeData.youtube.length}
                />
              </SegmentRevealed>
            </SegmentFull>
          </SegmentContainer>
          <div>
            <Button onClick={onPlay} disabled={playing || youtube === null}>
              Play
            </Button>
            <Button disabled={playing || gameOver} onClick={onSkip}>
              Skip
            </Button>
            <Button
              disabled={!selectedTrack || playing || gameOver}
              onClick={() => onSubmitTrack(selectedTrack)}
            >
              Submit
            </Button>
          </div>
          {gameOver ? (
            <AnswerTrack track={challengeData.spotify} />
          ) : (
            <>
              <SearchContainer>
                <SearchInput
                  value={searchTerm}
                  onChange={onSearchChange}
                  onKeyDown={onSearchKeyDown}
                />
                <Button onClick={onSearch}>Search</Button>
              </SearchContainer>
              {selectedTrack && (
                <TrackContainer>
                  <TrackImage src={selectedTrack.imageUrlSmall} />
                  <SelectedTrackTitle>{selectedTrack.name}</SelectedTrackTitle>
                </TrackContainer>
              )}
              {searchResults.map((track) => (
                <SearchResultContainer onClick={() => onSelectGuess(track)}>
                  <SearchResultImage src={track.imageUrlSmall} />
                  <SearchResultText>
                    {track.name} - {track.artists.join(", ")}
                  </SearchResultText>
                  <SearchResultText>{track.album}</SearchResultText>
                </SearchResultContainer>
              ))}
            </>
          )}
          <MiniLeaderboard
            users={users.filter((u) => u.guesses.length > 0)}
            spotifyId={challengeData.spotify.id}
          />
          <Leaderboard
            users={users.filter((u) => u.guesses.length > 0)}
            spotifyId={challengeData.spotify.id}
            gameOver={gameOver}
          />
          <Button onClick={() => navigate("past")}>Yesterday</Button>
          {/* <Button onClick={() => navigate("about")}>About</Button> */}
          <Button
            onClick={() =>
              window.open("https://wwa.dillonhammer.com", "_blank")
            }
          >
            Play WWA
          </Button>
          <div>
            <Button
              onClick={() => navigate("fixAnswer")}
              disabled={!gameOver && guesses.length !== 6}
            >
              Help I Got It Correct
            </Button>
          </div>
          <Changelog />
        </>
      ) : (
        <>
          <LoadingTitle>Songs with Associates</LoadingTitle>
          <div>Loading...</div>
        </>
      )}
    </>
  );
};

export default HomePage;
