import styled, { css, keyframes } from "styled-components";
import { User } from "../models/User.model";
import React, { useContext, useRef, useState } from "react";
import { ActiveUserContext } from "../utils/contexts";
import { searchYoutube } from "../api/Youtube.api";
import YouTube, { YouTubeEvent, YouTubePlayer } from "react-youtube";
import {
  IconContainer,
  LeaderboardDivider,
  LeaderboardSongLoading,
  LeaderboardSongPlaying,
  PlayIcon,
  PlayIconBackground,
  UserCardTitle,
  YouTubeBlocker,
  YouTubeContainer,
} from "./Common";
import { YouTubeVideo } from "../models/YouTubeVideo.model";
import {
  Badge,
  BadgeSection,
  generateBadgeModal,
  generateBadges,
} from "./Badges";
import { BADGE_MODAL_FADE_DURATION } from "../models/Constants";

interface LeaderboardProps {
  spotifyId: string;
  users: User[];
  gameOver: boolean;
}

const USER_CARD_WIDTH = 250;

const LeaderboardContainer = styled.div<{ width: number }>`
  margin-top: 50px;
  display: flex;
  overflow-x: auto;
  margin-bottom: 10px;
  justify-content: ${({ width }) =>
    window.innerWidth > width ? "center" : "left"};
`;

const UserCard = styled.div`
  width: ${USER_CARD_WIDTH}px;
  flex-shrink: 0;
`;

const RevealedAnswerContainer = styled.div<{ cursor: boolean }>`
  position: relative;
  background-color: ${(props) => props.theme.SECONDARY};
  height: 30px;
  width: 240px;
  margin: 0px auto 10px auto;
  cursor: ${({ cursor }) => (cursor ? "pointer" : "auto")};
`;

const RevealedAnswerImage = styled.img`
  position: absolute;
  left: 0px;
  width: 30px;
  height: 30px;
`;

const RevealedTrackTitle = styled.div`
  line-height: 30px;
  font-size: 12px;
  margin-left: 40px;
  margin-right: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const AnswerResultMarker = styled.div<{ correct: boolean }>`
  background-color: ${({ correct, theme }) =>
    correct ? theme.PRIMARY : theme.HIGHLIGHT};
  position: absolute;
  right: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
`;

const HiddenAnswerSkip = styled(RevealedAnswerContainer)``;

const HiddenAnswerIncorrect = styled(RevealedAnswerContainer)`
  background-color: ${(props) => props.theme.HIGHLIGHT};
`;

const HiddenAnswerCorrect = styled(RevealedAnswerContainer)`
  background-color: ${(props) => props.theme.PRIMARY};
`;

const BadgeModalBackgroundFadeIn = keyframes`
  0% { background-color: rgb(0,0,0,0) }
  100% { background-color: rgb(0,0,0,0.8) }
`;

const BadgeModalBackgroundFadeOut = keyframes`
  0% { background-color: rgb(0,0,0,0.8) }
  100% { background-color: rgb(0,0,0,0) }
`;

const BadgeModalContainerFadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 100 }
`;

const BadgeModalContainerFadeOut = keyframes`
  0% { opacity: 100 }
  100% { opacity: 0 }
`;

const BadgeModalBackground = styled.div<{ $fadingOut: boolean }>`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;

  animation: ${({ $fadingOut: fadingOut }) => {
    if (!fadingOut) {
      return css`
        ${BADGE_MODAL_FADE_DURATION}ms ${BadgeModalBackgroundFadeIn} forwards
      `;
    } else {
      return css`
        ${BADGE_MODAL_FADE_DURATION}ms ${BadgeModalBackgroundFadeOut} forwards
      `;
    }
  }};
`;

const BadgeModalContainer = styled.div<{ $fadingOut: boolean }>`
  width: 300px;
  height: 500px;
  left: ${(window.innerWidth - 300) / 2 - 8}px;
  top: ${(window.innerHeight - 500) / 2 - 8}px;

  position: fixed;
  background-color: ${(props) => props.theme.BACKGROUND};
  border: 8px solid ${(props) => props.theme.PRIMARY};
  border-radius: 10px;
  z-index: 3;
  cursor: auto;

  animation: ${({ $fadingOut: fadingOut }) => {
    if (!fadingOut) {
      return css`
        ${BADGE_MODAL_FADE_DURATION}ms ${BadgeModalContainerFadeIn} forwards
      `;
    } else {
      return css`
        ${BADGE_MODAL_FADE_DURATION}ms ${BadgeModalContainerFadeOut} forwards
      `;
    }
  }};
`;

const Leaderboard = ({ spotifyId, users, gameOver }: LeaderboardProps) => {
  const userId = useContext(ActiveUserContext);
  const [leaderboardYoutube, setLeaderboardYoutube] =
    useState<YouTubeVideo | null>(null);
  const [youtube, setYoutube] = useState<YouTubePlayer | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<{
    userId: string;
    index: number;
  } | null>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const leaderboardVideoTimeout = useRef<number | null>(null);
  const [badgeModalInfo, setBadgeModalInfo] = useState<{
    badge: Badge | null;
    user: User | null;
  }>({ badge: null, user: null });
  const [badgeModalFadeOut, setBadgeModalFadeOut] = useState<boolean>(false);

  const toggleBadge = (badge: Badge, user: User) => {
    setBadgeModalInfo({ badge, user });
  };

  const closeBadgeModal = () => {
    setBadgeModalFadeOut(true);
    setTimeout(() => {
      setBadgeModalInfo({ badge: null, user: null });
      setBadgeModalFadeOut(false);
    }, BADGE_MODAL_FADE_DURATION);
  };

  const handleBadgeModalClick: React.MouseEventHandler<HTMLDivElement> = (
    e
  ) => {
    e.stopPropagation();
  };

  const onClickLeaderboardGuess = (
    name: string,
    artist: string,
    userId: string,
    index: number
  ) => {
    if (
      userId === selectedLocation?.userId &&
      index === selectedLocation.index
    ) {
      if (!playing && !loading) {
        if (youtube) youtube.playVideo();
      } else {
        setSelectedLocation(null);
        setLeaderboardYoutube(null);
        setPlaying(false);
      }
    } else {
      setLoading(true);
      setPlaying(false);
      setSelectedLocation({ userId, index });
      setLeaderboardYoutube(null);

      const searchTerm = `${name} lyrics ${artist}`;
      searchYoutube(searchTerm).then((response) => {
        setLeaderboardYoutube(response);
      });
    }
  };

  const onPlayerReady = (event: YouTubeEvent) => {
    setYoutube(event.target);
    setLoading(false);
  };

  const onStateChange = (e: YouTubeEvent) => {
    if (leaderboardYoutube && e.data === 1) {
      // 1 == "Playing"
      setPlaying(true);
      const duration = leaderboardYoutube.length;

      const id = window.setTimeout(() => {
        youtube.pauseVideo();
        youtube.seekTo(0);
        setPlaying(false);
        setLoading(false);
        setSelectedLocation(null);
      }, duration * 1000);

      if (leaderboardVideoTimeout.current !== null)
        window.clearTimeout(leaderboardVideoTimeout.current);

      leaderboardVideoTimeout.current = id;
    }
  };

  return (
    <>
      <LeaderboardContainer width={USER_CARD_WIDTH * users.length}>
        {badgeModalInfo.badge && badgeModalInfo.user && (
          <BadgeModalBackground
            onClick={() => closeBadgeModal()}
            $fadingOut={badgeModalFadeOut}
          >
            <BadgeModalContainer
              onClick={handleBadgeModalClick}
              $fadingOut={badgeModalFadeOut}
            >
              {generateBadgeModal(
                badgeModalInfo.badge,
                badgeModalInfo.user,
                closeBadgeModal
              )}
            </BadgeModalContainer>
          </BadgeModalBackground>
        )}
        {users
          .sort(
            (u1, u2) =>
              (u1.firstGuessTimestamp || 0) - (u2.firstGuessTimestamp || 0)
          )
          .map((u) => (
            <UserCard key={u.userId}>
              <UserCardTitle $self={u.userId === userId}>
                {u.userId}
              </UserCardTitle>
              <LeaderboardDivider />
              <BadgeSection>
                {generateBadges(u, spotifyId, toggleBadge)}
              </BadgeSection>
              <LeaderboardDivider />
              {u.guesses.map((g, index) =>
                gameOver || u.userId === userId ? (
                  <RevealedAnswerContainer
                    cursor={g !== null}
                    onClick={() =>
                      g &&
                      onClickLeaderboardGuess(
                        g.name,
                        g.artists[0],
                        u.userId,
                        index
                      )
                    }
                  >
                    {g && (
                      <>
                        {u.userId === selectedLocation?.userId &&
                        index === selectedLocation.index ? (
                          loading ? (
                            <LeaderboardSongLoading />
                          ) : playing && leaderboardYoutube ? (
                            <>
                              <RevealedAnswerImage src={g.imageUrlSmall} />
                              <RevealedTrackTitle>{g.name}</RevealedTrackTitle>
                              <AnswerResultMarker
                                correct={g.id === spotifyId}
                              />
                              <LeaderboardSongPlaying
                                fullLength={leaderboardYoutube.length}
                              />
                            </>
                          ) : (
                            <PlayIconBackground>
                              <IconContainer>
                                <PlayIcon />
                              </IconContainer>
                            </PlayIconBackground>
                          )
                        ) : (
                          <>
                            <RevealedAnswerImage src={g.imageUrlSmall} />
                            <RevealedTrackTitle>{g.name}</RevealedTrackTitle>
                            <AnswerResultMarker correct={g.id === spotifyId} />
                          </>
                        )}
                      </>
                    )}
                  </RevealedAnswerContainer>
                ) : g ? (
                  g.id === spotifyId ? (
                    <HiddenAnswerCorrect cursor={false} />
                  ) : (
                    <HiddenAnswerIncorrect cursor={false} />
                  )
                ) : (
                  <HiddenAnswerSkip cursor={false} />
                )
              )}
            </UserCard>
          ))}
      </LeaderboardContainer>
      <YouTubeContainer>
        {selectedLocation && leaderboardYoutube && (
          <YouTube
            videoId={leaderboardYoutube.id}
            onReady={onPlayerReady}
            onStateChange={onStateChange}
            opts={{
              height: "70",
              width: "200",
            }}
          />
        )}
        <YouTubeBlocker />
      </YouTubeContainer>
    </>
  );
};

export default Leaderboard;
