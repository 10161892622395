import styled from "styled-components";

const Container = styled.div`
  background-color: ${(props) => props.theme.PRIMARY};
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  position: fixed;
  width: 100%;
  bottom: 0px;
`;

const PersonalLink = styled.a`
  color: ${(props) => props.theme.SECONDARY};
  text-decoration: none;
`;

const Footer = () => {
  return (
    <Container>
      Made by{" "}
      <PersonalLink
        href="https://dillonhammer.com"
        target="_blank"
        rel="noreferrer"
      >
        Dillon Hammer
      </PersonalLink>
    </Container>
  );
};

export default Footer;
