import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "./Common";

interface LoginProps {
  onSubmitName: (name: string) => void;
}

const LoginContainer = styled.div`
  font-family: monospace;
  font-size: 24px;
  text-align: center;
  margin: 20px;
  color: ${(props) => props.theme.TEXT};
`;

const NameInput = styled.input`
  font-family: monospace;
  display: block;
  margin: 20px auto;
  font-size: 24px;
  border: 5px solid ${(props) => props.theme.DISABLED};
  border-radius: 5px;
  padding: 8px;
  color: ${(props) => props.theme.TEXT};
  background-color: ${(props) => props.theme.BACKGROUND};

  &:focus {
    outline: 0;
    border: 5px solid ${(props) => props.theme.HIGHLIGHT};
  }
`;

const EnterButton = styled(Button)`
  font-size: 24px;
`;

const Login = ({ onSubmitName }: LoginProps) => {
  const [name, setName] = useState("");

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value.length > 12) {
      setName(e.target.value.substring(0, 12));
    } else {
      setName(e.target.value);
    }
  };

  const onNameKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") onSubmitName(name);
  };

  return (
    <LoginContainer>
      <div>Enter your name</div>
      <NameInput
        autoFocus
        value={name}
        onChange={onNameChange}
        onKeyDown={onNameKeyDown}
      />
      <EnterButton onClick={() => onSubmitName(name)}>Enter</EnterButton>
    </LoginContainer>
  );
};

export default Login;
