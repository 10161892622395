import axios from "axios";
import { YouTubeVideo } from "../models/YouTubeVideo.model";

const SERVER = process.env.REACT_APP_SERVER_BASE;

export const searchYoutube = async (
  searchTerm: string
): Promise<YouTubeVideo> => {
  const video = await axios.get<YouTubeVideo>(
    `${SERVER}/youtube/${encodeURIComponent(searchTerm)}`
  );

  return video.data;
};
