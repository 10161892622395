import axios from "axios";
import { FixUserGuess, User } from "../models/User.model";

const SERVER = process.env.REACT_APP_SERVER_BASE;

export const getUsersByDate = async (date: string): Promise<User[]> => {
  const response = await axios.get<User[]>(`${SERVER}/users/${date}`);

  return response.data;
};

export const updateUser = async (userPayload: User): Promise<void> => {
  await axios.post(`${SERVER}/user`, userPayload);

  return;
};

export const fixUserGuess = async (payload: FixUserGuess): Promise<void> => {
  await axios.post(`${SERVER}/fixGuess`, payload);

  return;
};
