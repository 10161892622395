import styled from "styled-components";
import { Button, Tab, TitleText } from "./Common";
import { useContext, useEffect, useState } from "react";

interface AboutPageProps {
  navigate: (t: Tab) => void;
}

const AboutContainer = styled.div`
  margin-top: 60px;
`;

const AboutTitle = styled.div`
  height: 60px;
  line-height: 60px;
`;

const AboutText = styled.div`
  margin: 30px;
`;

const AboutPage = ({ navigate }: AboutPageProps) => {
  return (
    <AboutContainer>
      <AboutTitle>About</AboutTitle>
      <AboutText>
        Explanations of how this works, in no particular order:
      </AboutText>
      <AboutText>
        The solutions are picked at random from a set of spotify playlists,
        right now it picks from these 5:
      </AboutText>
      <ul>
        <li>
          <a
            href="https://open.spotify.com/playlist/7EAqBCOVkDZcbccjxZmgjp"
            target="_blank"
          >
            Rolling Stone 500 Greatest Songs of All Time
          </a>
        </li>
        <li>
          <a
            href="https://open.spotify.com/playlist/37i9dQZF1DX4UtSsGT1Sbe"
            target="_blank"
          >
            All Out 1980s (Spotify-maintained)
          </a>
        </li>
        <li>
          <a
            href="https://open.spotify.com/playlist/37i9dQZF1DXbTxeAdrVG2l"
            target="_blank"
          >
            All Out 1990s (Spotify-maintained)
          </a>
        </li>
        <li>
          <a
            href="https://open.spotify.com/playlist/37i9dQZF1DX4o1oenSJRJd"
            target="_blank"
          >
            All Out 2000s (Spotify-maintained)
          </a>
        </li>
        <li>
          <a
            href="https://open.spotify.com/playlist/37i9dQZF1DX5Ejj0EkURtP"
            target="_blank"
          >
            All Out 2010s (Spotify-maintained)
          </a>
        </li>
      </ul>
      <AboutText>
        I do plan on expanding the solution set in the future
      </AboutText>
      <AboutText>
        The search also uses Spotify, which is why there are sometimes multiple
        versions of the same song.
      </AboutText>
      <Button onClick={() => navigate("homepage")}>Back</Button>
    </AboutContainer>
  );
};

export default AboutPage;
