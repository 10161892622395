import axios from "axios";
import { Challenge } from "../models/Challenge.model";

const SERVER = process.env.REACT_APP_SERVER_BASE;

export const getChallenge = async (date: string): Promise<Challenge> => {
  const response = await axios.get<Challenge>(`${SERVER}/challenge/${date}`);

  if (response.status !== 200) throw new Error("Puzzle not found");
  else return response.data;
};
