import axios from "axios";
import { SpotifyTrack } from "../models/SpotifyTrack.model";

const SERVER = process.env.REACT_APP_SERVER_BASE;

export const searchSpotifyTracks = async (
  searchTerm: string
): Promise<SpotifyTrack[]> => {
  const tracks = await axios.get<SpotifyTrack[]>(
    `${SERVER}/spotify/${encodeURIComponent(searchTerm)}`
  );

  return tracks.data;
};
