import styled, { css, keyframes } from "styled-components";

export type Tab =
  | "homepage"
  | "theme"
  | "past"
  | "about"
  | "badges"
  | "fixAnswer";

export const Page = styled.div`
  font-size: 18px;
  font-family: monospace;
  text-align: center;
  color: ${(props) => props.theme.TEXT};
`;

export const Button = styled.button<{ disabled?: boolean }>`
  font-family: monospace;
  font-size: 18px;
  margin: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: ${(props) => props.theme.TEXT};
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.DISABLED : theme.SECONDARY};

  &:hover {
    background-color: ${({ disabled, theme }) =>
      disabled ? "" : theme.HIGHLIGHT};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
`;

export const YouTubeContainer = styled.div`
  position: relative;
  height: 78px;
  width: 100%;
  margin-top: 60px;
`;

export const YouTubeBlocker = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.BACKGROUND};
  top: 0px;
  height: 78px;
  width: 100%;
`;

export const TitleText = styled.div`
  position: absolute;
  top: 0px;
  text-align: center;
  line-height: 18px;
  margin: 30px 0px 30px 0px;
  width: 100%;
`;

export const LoadingTitle = styled.div`
  margin: 90px 0px 30px 0px;
`;

export const fillLeftToRight = keyframes`
  0% {
    width: 0%;
  } 100% {
    width: 100%;
  }
`;

export const shrink = keyframes`
  0% {
    width: 100%;
  } 100% {
    width: 0%;
  }
`;

export const SEGMENT_TOTALS = [1, 2, 4, 7, 11, 16, 16];
export const SECOND_WIDTH = 20;

export const SegmentContainer = styled.div`
  height: 40px;
  margin-top: 30px;
`;

export const SegmentFull = styled.div`
  display: inline-block;
  text-align: left;
  background-color: ${(props) => props.theme.DISABLED};
  width: ${16 * SECOND_WIDTH}px;
  height: 20px;
  margin: 10px 0px;
`;

export const SegmentRevealed = styled.div<{
  guessesLength: number;
  gameOver: boolean;
}>`
  display: inline-block;
  height: 20px;
  background-color: ${(props) => props.theme.SECONDARY};
  text-align: left;
  width: ${({ guessesLength, gameOver }) =>
    SEGMENT_TOTALS[gameOver ? SEGMENT_TOTALS.length - 1 : guessesLength] *
    SECOND_WIDTH}px;
`;

export const SegmentPlaying = styled.div<{
  guessesLength: number;
  playing: boolean;
  gameOver: boolean;
  fullLength: number;
}>`
  display: inline-block;
  background-color: ${(props) => props.theme.PRIMARY};
  height: 100%;

  animation: ${({ guessesLength, playing, gameOver, fullLength }) => {
    if (playing) {
      return css`
        ${(gameOver ? fullLength : SEGMENT_TOTALS[guessesLength]) * 1000}ms
        ${fillLeftToRight} forwards
      `;
    } else {
      return "none";
    }
  }};
  animation-timing-function: linear;
`;

export const LeaderboardSongLoading = styled.div`
  background-color: ${(props) => props.theme.PRIMARY};
  height: 100%;
  width: 0%;

  animation: 500ms ${fillLeftToRight} infinite alternate;
  animation-timing-function: ease-in-out;
`;

export const LeaderboardSongPlaying = styled.div<{ fullLength: number }>`
  position: absolute;
  top: 0px;
  right: 0px;

  background-color: ${(props) => props.theme.BACKGROUND};
  height: 100%;
  width: 100px;

  animation: ${({ fullLength }) => {
    return css`
      ${fullLength * 1000}ms ${shrink} infinite
    `;
  }};
  animation-timing-function: linear;
`;

export const LeaderboardDivider = styled.hr`
  background-color: ${(props) => props.theme.TEXT};
  border: none;
  height: 2px;
  margin: 11px 0px;
`;

export const PlayIconBackground = styled.div`
  background-color: ${(props) => props.theme.PRIMARY};
  height: 100%;
  width: 100%;
`;

export const IconContainer = styled.div`
  height: 30px;
  width: 30px;
  margin: auto;
`;

export const PlayIcon = styled.div`
  background-color: ${(props) => props.theme.SECONDARY};
  height: 100%;
  width: 100%;
  clip-path: polygon(24% 20%, 76% 50%, 24% 80%);
`;

export const UserCardTitle = styled.div<{ $self: boolean }>`
  color: ${(props) => props.$self && props.theme.PRIMARY};
  margin: 0px 5px;
`;

export const TrackContainer = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.SECONDARY};
  height: 40px;
  width: 320px;
  margin: 0px auto 10px auto;
`;

export const TrackTitle = styled.div`
  line-height: 40px;
  font-size: 14px;
  margin-left: 50px;
  margin-right: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const GuessResultMarker = styled.div<{ correct: boolean }>`
  background-color: ${({ correct, theme }) =>
    correct ? theme.PRIMARY : theme.HIGHLIGHT};
  position: absolute;
  right: 0px;
  top: 0px;
  width: 40px;
  height: 40px;
`;

export const TrackImage = styled.img`
  position: absolute;
  left: 0px;
  width: 40px;
  height: 40px;
`;

export const SkippedGuess = styled(TrackContainer)``;
