import styled from "styled-components";
import { User } from "../models/User.model";
import { Button } from "./Common";

export type Badge = "correctStreak" | "participatedStreak";

export const BadgeSection = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px 10px;
  height: 34px;
`;

const BadgeContainer = styled.div<{ size: number }>`
  flex-shrink: 0;
  position: relative;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  margin: 2px;
`;

const BaseBadge = styled.div<{ $clickable: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: ${(props) => (props.$clickable ? "pointer" : "auto")};
`;

const BadgeBackground = styled(BaseBadge)`
  clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
  background-color: ${(props) => props.theme.PRIMARY};

  &:hover {
    background-color: ${(props) =>
      props.$clickable ? props.theme.HIGHLIGHT : ""};
  }
`;

const ParticipatedStreakCount = styled.div<{ size: number }>`
  color: ${(props) => props.theme.BACKGROUND};
  line-height: ${(props) => props.size}px;
  font-size: ${(props) => props.size * 0.6}px;
`;

const ExtendedBadgeContainer = styled.div<{ size: number; notes: number }>`
  flex-shrink: 0;
  position: relative;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size + (props.notes - 1) * (props.size / 2)}px;
  margin: 2px;
`;

const ExtendedBadgeBackground = styled.div<{
  $clickable: boolean;
  notes: number;
}>`
  background-color: ${(props) => props.theme.PRIMARY};
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: ${(props) => (props.$clickable ? "pointer" : "auto")};

  &:hover {
    background-color: ${(props) =>
      props.$clickable ? props.theme.HIGHLIGHT : ""};
  }

  clip-path: ${({ notes }) => {
    const halfBadgeSlices = notes + 1;
    const effectiveWidth = halfBadgeSlices * 50;
    const leftVertex = (50 * 100) / effectiveWidth;
    const rightVertex = 100 - leftVertex;
    const leftMargin = (5 * 100) / effectiveWidth;
    const rightMargin = 100 - leftMargin;

    return `polygon(
      ${leftVertex}% 0%,
      ${rightVertex}% 0%,
      ${rightMargin}% 25%,
      ${rightMargin}% 75%,
      ${rightVertex}% 100%,
      ${leftVertex}% 100%,
      ${leftMargin}% 75%,
      ${leftMargin}% 25%
    )`;
  }};
`;

const MusicNoteContainer = styled.div<{ size: number; note: number }>`
  position: absolute;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  margin-left: ${({ note, size }) => note * (size / 2)}px;
`;

const MusicNoteHead = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.BACKGROUND};
  border-radius: 50%;
  width: 30%;
  height: 20%;
  margin: 60% 0% 0% 35%;
`;

const MusicNoteStem = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.BACKGROUND};
  width: 6%;
  height: 50%;
  margin: 20% 0% 0% 59%;
`;

const MusicNoteBar = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.BACKGROUND};
  width: 56%;
  height: 8%;
  margin: 20% 0% 0% 9%;
`;

const BadgeModalBadgeRow = styled.div`
  margin: 50px 0px;
  display: flex;
  justify-content: center;
`;

const BadgeTitle = styled.div`
  font-size: 30px;
`;

const BadgeDescription = styled.div`
  margin: 40px 20px 20px 20px;
  white-space: pre-wrap;
`;

const BadgeModalBottom = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

const BadgeRequirement = styled.div`
  font-size: 14px;
`;

const BadgeModalCloseButton = styled(Button)`
  margin: 20px 0px;
`;

export const generateBadges = (
  user: User,
  spotifyId: string,
  toggleBadge: (badge: Badge, user: User) => void
): JSX.Element[] => {
  const badges: JSX.Element[] = [];

  if (user.correctStreak && user.guesses.some((g) => spotifyId === g?.id)) {
    badges.push(
      <ExtendedBadgeContainer
        size={30}
        notes={user.correctStreak}
        key={"correctStreak"}
      >
        <ExtendedBadgeBackground
          notes={user.correctStreak}
          onClick={() => toggleBadge("correctStreak", user)}
          $clickable
        >
          {generateMusicNotes(user.correctStreak, 30)}
        </ExtendedBadgeBackground>
      </ExtendedBadgeContainer>
    );
  }

  if (user.participatedStreak > 1) {
    badges.push(
      <BadgeContainer size={30} key={"participatedStreak"}>
        <BadgeBackground
          onClick={() => toggleBadge("participatedStreak", user)}
          $clickable
        >
          <ParticipatedStreakCount size={30}>
            {user.participatedStreak}
          </ParticipatedStreakCount>
        </BadgeBackground>
      </BadgeContainer>
    );
  }

  return badges;
};

export const generateBadgeModal = (
  badge: Badge,
  user: User,
  closeBadgeModal: () => void
): JSX.Element => {
  let badgeIcon, badgeTitle, badgeDescription, badgeRequirement;

  switch (badge) {
    case "participatedStreak":
      badgeIcon = (
        <BadgeContainer size={90}>
          <BadgeBackground $clickable={false}>
            <ParticipatedStreakCount size={90}>
              {user.participatedStreak}
            </ParticipatedStreakCount>
          </BadgeBackground>
        </BadgeContainer>
      );
      badgeTitle = "Participated Streak";
      badgeDescription = `${user.userId} has played ${
        user.participatedStreak
      } ${user.participatedStreak > 1 ? "days" : "day"} in a row!`;
      break;
    case "correctStreak":
      badgeIcon = (
        <ExtendedBadgeContainer size={90} notes={user.correctStreak}>
          <ExtendedBadgeBackground
            notes={user.correctStreak}
            $clickable={false}
          >
            {generateMusicNotes(user.correctStreak, 90)}
          </ExtendedBadgeBackground>
        </ExtendedBadgeContainer>
      );
      badgeTitle = "Correct Streak";
      badgeDescription = `${user.userId} has correctly guessed the song ${
        user.correctStreak
      } ${user.correctStreak > 1 ? "days" : "day"} in a row!`;
      break;
  }

  return (
    <>
      <BadgeModalBadgeRow>{badgeIcon}</BadgeModalBadgeRow>
      <BadgeTitle>{badgeTitle}</BadgeTitle>
      <BadgeDescription>{badgeDescription}</BadgeDescription>
      <BadgeModalBottom>
        {badgeRequirement && (
          <BadgeRequirement>{badgeRequirement}</BadgeRequirement>
        )}
        <BadgeModalCloseButton onClick={() => closeBadgeModal()}>
          Close
        </BadgeModalCloseButton>
      </BadgeModalBottom>
    </>
  );
};

export const generateMusicNotes = (notes: number, size: number) => {
  const elements: JSX.Element[] = [];

  for (let i = 0; i < notes; i++) {
    elements.push(
      <MusicNoteContainer note={i} size={size}>
        <MusicNoteHead />
        <MusicNoteStem />
        {i > 0 && <MusicNoteBar />}
      </MusicNoteContainer>
    );
  }

  return elements;
};

export const BadgeWorkshopTab = () => {
  const SIZE = 30;
  const NOTES = 4;
  return (
    <div>
      <br />
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <BadgeContainer size={SIZE}>
          <BadgeBackground $clickable={false}>
            <ParticipatedStreakCount size={SIZE}>
              {Math.floor(Math.random() * 20)}
            </ParticipatedStreakCount>
          </BadgeBackground>
        </BadgeContainer>
        <BadgeContainer size={SIZE}>
          <BadgeBackground $clickable={false}>
            <MusicNoteHead />
            <MusicNoteStem />
          </BadgeBackground>
        </BadgeContainer>
        <ExtendedBadgeContainer size={SIZE} notes={NOTES}>
          <ExtendedBadgeBackground
            notes={NOTES}
            $clickable={false}
          ></ExtendedBadgeBackground>
          {generateMusicNotes(NOTES, SIZE)}
        </ExtendedBadgeContainer>
      </div>
    </div>
  );
};
