import styled from "styled-components";
import {
  Button,
  GuessResultMarker,
  SkippedGuess,
  Tab,
  TrackContainer,
  TrackImage,
  TrackTitle,
} from "./Common";
import { useContext, useEffect, useState } from "react";
import { ActiveUserContext } from "../utils/contexts";
import { getDateString } from "../models/Utils";
import { FixUserGuess, User } from "../models/User.model";
import { Challenge } from "../models/Challenge.model";
import { getChallenge } from "../api/Challenge.api";
import { fixUserGuess, getUsersByDate } from "../api/User.api";
import { SpotifyTrack } from "../models/SpotifyTrack.model";
import AnswerTrack from "./AnswerTrack";

interface FixAnswerProps {
  navigate: (t: Tab) => void;
}

const Container = styled.div`
  margin-top: 90px;
`;

const ClickableTrackContainer = styled(TrackContainer)`
  cursor: pointer;
`;

const SelectionSection = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

const SelectionPlaceholder = styled.div`
  background-color: ${(props) => props.theme.HIGHLIGHT};
  margin-top: 30px;
  width: 200px;
  height: 290px;
`;

const ButtonSection = styled.div`
  margin-top: 30px;
  min-height: 260px;
`;

const FixAnswer = ({ navigate }: FixAnswerProps) => {
  const userId = useContext(ActiveUserContext);
  const [challengeData, setChallengeData] = useState<Challenge | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [selectedGuess, setSelectedGuess] = useState<SpotifyTrack | null>(null);
  const [selectedGuessIndex, setSelectedGuessIndex] = useState<number | null>(
    null
  );
  const [submitStage, setSubmitStage] = useState<number>(0);
  const [submitted, setSubmitted] = useState<boolean>(false);

  useEffect(() => {
    const today = getDateString(new Date());
    getChallenge(today).then((res) => {
      setChallengeData(res);
      getUsersByDate(today).then((users) => {
        const currentUser = users.find((u) => u.userId === userId) || null;
        setUser(currentUser);
      });
    });
  }, [userId]);

  const onSelectTrack = (track: SpotifyTrack, index: number) => {
    setSelectedGuess(track);
    setSelectedGuessIndex(index);
    setSubmitStage(1);
  };

  const onSubmitCorrection = () => {
    if (challengeData && user && selectedGuessIndex !== null) {
      const payload: FixUserGuess = {
        date: user.date,
        userId: user.userId,
        guessIndex: selectedGuessIndex,
        correctTrack: challengeData.spotify,
      };

      fixUserGuess(payload);
      setSubmitted(true);
    }
  };

  return (
    <>
      {challengeData && user ? (
        <Container>
          <div>Select which guess is correct</div>
          <br />
          {user.guesses.map((track, index) =>
            track ? (
              <ClickableTrackContainer
                onClick={() => onSelectTrack(track, index)}
              >
                <TrackImage src={track.imageUrlSmall} />
                <TrackTitle>{track.name}</TrackTitle>
                <GuessResultMarker
                  correct={track.id === challengeData.spotify.id}
                />
              </ClickableTrackContainer>
            ) : (
              <SkippedGuess />
            )
          )}
          {!submitted ? (
            <>
              <SelectionSection>
                <div>
                  <div>Correct Answer</div>
                  <AnswerTrack track={challengeData.spotify} />
                </div>
                <div>
                  <div>Your Answer</div>
                  {selectedGuess ? (
                    <AnswerTrack track={selectedGuess} />
                  ) : (
                    <SelectionPlaceholder />
                  )}
                </div>
              </SelectionSection>
              <ButtonSection>
                {submitStage > 0 && (
                  <Button onClick={() => setSubmitStage(2)}>
                    I Solemnly Swear These 2 Songs Are The Same I Just Picked A
                    Different Spotify Version
                  </Button>
                )}
                {submitStage > 1 && (
                  <>
                    <div>Are you sure?</div>
                    <Button onClick={() => setSubmitStage(3)}>I'm sure</Button>
                  </>
                )}
                {submitStage > 2 && (
                  <>
                    <div>Cool cool</div>
                    <Button onClick={onSubmitCorrection}>Submit</Button>
                  </>
                )}
              </ButtonSection>
            </>
          ) : (
            <>
              <br />
              <div>Submitted!</div>
              <br />
            </>
          )}
          <Button onClick={() => navigate("homepage")}>Back</Button>
          <br />
          <br />
          <br />
        </Container>
      ) : (
        <>
          <br />
          <br />
          <br />
          <br />
          <div>Loading...</div>
        </>
      )}
    </>
  );
};

export default FixAnswer;
