import YouTube, { YouTubeEvent, YouTubePlayer } from "react-youtube";
import {
  Button,
  LoadingTitle,
  SegmentContainer,
  SegmentFull,
  SegmentPlaying,
  SegmentRevealed,
  Tab,
  TitleText,
  YouTubeBlocker,
  YouTubeContainer,
} from "./Common";
import { useContext, useEffect, useState } from "react";
import { Challenge } from "../models/Challenge.model";
import { ActiveUserContext } from "../utils/contexts";
import { User } from "../models/User.model";
import { getDateString } from "../models/Utils";
import { getChallenge } from "../api/Challenge.api";
import { getUsersByDate } from "../api/User.api";
import AnswerTrack from "./AnswerTrack";
import MiniLeaderboard from "./MiniLeaderboard";
import Leaderboard from "./Leaderboard";

interface PastChallengesProps {
  navigate: (t: Tab) => void;
}

const PastChallenges = ({ navigate }: PastChallengesProps) => {
  const userId = useContext(ActiveUserContext);
  const [yesterdayTitle, setYesterdayTitle] = useState<string>("");
  const [challengeData, setChallengeData] = useState<Challenge | null>(null);
  const [youtube, setYoutube] = useState<YouTubePlayer | null>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [users, setUsers] = useState<User[] | null>(null);

  useEffect(() => {
    const yesterday = new Date();
    yesterday.setTime(yesterday.getTime() - 1 * 24 * 60 * 60 * 1000);
    const yesterdayDisplayString = yesterday.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    setYesterdayTitle(yesterdayDisplayString);
    const yesterdayApiString = getDateString(yesterday);
    getChallenge(yesterdayApiString).then((res) => {
      setChallengeData(res);
      getUsersByDate(yesterdayApiString).then((users) => {
        setUsers(users);
      });
    });
  }, [userId]);

  const onPlayerReady = (event: YouTubeEvent) => {
    setYoutube(event.target);
  };

  const onPlay = () => {
    if (youtube) youtube.playVideo();
  };

  const onPause = () => {
    if (youtube) youtube.pauseVideo();
  };

  const onRestart = () => {
    if (youtube) youtube.seekTo(0);
  };

  const onStateChange = (e: YouTubeEvent) => {
    if (challengeData && e.data === 1) {
      // 1 == "Playing"
      setPlaying(true);
    } else if (e.data === 0 || e.data === 2) {
      // 0 == "Ended", 2 == "Paused"
      setPlaying(false);
    }
  };

  return (
    <>
      {challengeData && users ? (
        <>
          <YouTubeContainer>
            <YouTube
              videoId={challengeData.youtube.id}
              onReady={onPlayerReady}
              onStateChange={onStateChange}
              opts={{
                height: "70",
                width: "200",
              }}
            />
            <YouTubeBlocker />
            <TitleText>Songs with Associates</TitleText>
          </YouTubeContainer>
          <div>{yesterdayTitle}</div>
          <AnswerTrack track={challengeData.spotify} />
          <SegmentContainer>
            <SegmentFull>
              <SegmentRevealed guessesLength={0} gameOver={true}>
                <SegmentPlaying
                  guessesLength={0}
                  playing={playing}
                  gameOver={true}
                  fullLength={challengeData.youtube.length}
                />
              </SegmentRevealed>
            </SegmentFull>
          </SegmentContainer>
          <div>
            <Button onClick={onPlay} disabled={playing || youtube === null}>
              Play
            </Button>
            {/* <Button disabled={!playing || youtube === null} onClick={onPause}>
              Pause
            </Button>
            <Button disabled={youtube === null} onClick={onRestart}>
              Restart
            </Button> */}
          </div>
          <MiniLeaderboard users={users} spotifyId={challengeData.spotify.id} />
          <Leaderboard
            users={users}
            spotifyId={challengeData.spotify.id}
            gameOver={true}
          />
          <Button onClick={() => navigate("homepage")}>Back</Button>
          <br />
          <br />
          <br />
        </>
      ) : (
        <>
          <LoadingTitle>Songs with Associates</LoadingTitle>
          <div>Loading...</div>
        </>
      )}
    </>
  );
};

export default PastChallenges;
