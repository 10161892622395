import styled from "styled-components";
import { Button } from "./Common";

interface HeaderProps {
  userId: string;
  onThemeChange: () => void;
  onLogout: () => void;
}

export const InfoBar = styled.div`
  background-color: ${(props) => props.theme.SECONDARY};
  height: 60px;
  line-height: 60px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1;
`;

export const ThemeButton = styled(Button)`
  float: left;
  background-color: ${(props) => props.theme.DISABLED};
`;

export const Username = styled.span`
  color: ${(props) => props.theme.PRIMARY};
`;

export const LogoutButton = styled(Button)`
  float: right;
  background-color: ${(props) => props.theme.DISABLED};
`;

const Header = ({ userId, onThemeChange, onLogout }: HeaderProps) => {
  return (
    <InfoBar>
      <ThemeButton onClick={onThemeChange}>Theme</ThemeButton>
      <Username>{userId}</Username>
      <LogoutButton onClick={onLogout}>Logout</LogoutButton>
    </InfoBar>
  );
};

export default Header;
