import styled from "styled-components";
import { User } from "../models/User.model";

interface MiniLeaderboardProps {
  users: User[];
  spotifyId: string;
}

const MARKER_SIZE = 20;

const MiniLeaderboardContainer = styled.div<{ width: number }>`
  margin-top: 50px;
  display: flex;
  justify-content: ${({ width }) =>
    window.innerWidth > width ? "center" : "left"};
`;

const Column = styled.div``;

const HiddenAnswerMarker = styled.div`
  width: ${MARKER_SIZE}px;
  height: ${MARKER_SIZE}px;
  margin: auto;
  background-color: ${(props) => props.theme.SECONDARY};
`;

const HiddenAnswerCorrect = styled(HiddenAnswerMarker)`
  background-color: ${(props) => props.theme.PRIMARY};
`;

const HiddenAnswerIncorrect = styled(HiddenAnswerMarker)`
  background-color: ${(props) => props.theme.HIGHLIGHT};
`;

const MiniLeaderboard = ({ users, spotifyId }: MiniLeaderboardProps) => {
  return (
    <MiniLeaderboardContainer width={MARKER_SIZE * users.length}>
      {users
        .sort(
          (u1, u2) =>
            (u1.firstGuessTimestamp || 0) - (u2.firstGuessTimestamp || 0)
        )
        .map((u) => (
          <Column>
            {u.guesses.map((g) =>
              g ? (
                g.id === spotifyId ? (
                  <HiddenAnswerCorrect />
                ) : (
                  <HiddenAnswerIncorrect />
                )
              ) : (
                <HiddenAnswerMarker />
              )
            )}
          </Column>
        ))}
    </MiniLeaderboardContainer>
  );
};

export default MiniLeaderboard;
