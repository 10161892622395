import styled from "styled-components";
import { THEMES, Theme } from "../models/Colors";
import { useEffect, useState } from "react";

interface ThemeSelectorProps {
  onThemeSelect: (t: Theme) => void;
}

const SelectorContainer = styled.div`
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

const ThemeOption = styled.div<{ theme: Theme }>`
  min-width: 250px;
  flex: 1;
  height: 100px;
  line-height: 100px;
  font-size: 20px;
  color: ${({ theme }) => theme.PRIMARY};
  background-color: ${({ theme }) => theme.SECONDARY};
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.HIGHLIGHT};
  }
`;

const Highlight = styled.span<{ theme: Theme }>`
  background-color: ${({ theme }) => theme.BACKGROUND};
  padding: 8px 16px;
  border-radius: 5px;
`;

const ThemeSelector = ({ onThemeSelect }: ThemeSelectorProps) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <SelectorContainer>
      {THEMES.map((theme) => (
        <ThemeOption
          key={theme.name}
          theme={theme}
          onClick={() => onThemeSelect(theme)}
        >
          <Highlight theme={theme}>{theme.name}</Highlight>
        </ThemeOption>
      ))}
    </SelectorContainer>
  );
};

export default ThemeSelector;
