import { useEffect, useState } from "react";
import HomePage from "./components/HomePage";
import { ActiveUserContext } from "./utils/contexts";
import Login from "./components/Login";
import { ThemeProvider } from "styled-components";
import { THEMES, Theme } from "./models/Colors";
import { Page, Tab } from "./components/Common";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ThemeSelector from "./components/ThemeSelector";
import PastChallenges from "./components/PastChallenges";
import AboutPage from "./components/AboutPage";
import { BadgeWorkshopTab } from "./components/Badges";
import FixAnswer from "./components/FixAnswer";

function App() {
  const [userId, setUserId] = useState("");
  const [theme, setTheme] = useState(
    THEMES[Math.floor(Math.random() * THEMES.length)]
  );
  const [currentTab, setCurrentTab] = useState<Tab>("homepage");

  useEffect(() => {
    document.body.style.backgroundColor = theme.BACKGROUND;
    const name = window.localStorage.getItem("username");
    if (name) setUserId(name);
  }, [theme.BACKGROUND]);

  const onSubmitName = (name: string) => {
    window.localStorage.setItem("username", name.trim());
    setUserId(name.trim());
  };

  const onLogout = () => {
    window.localStorage.removeItem("username");
    setUserId("");
  };

  const onThemeChange = () => {
    if (currentTab !== "theme") navigate("theme");
    else navigate("homepage");
  };

  const onThemeSelect = (t: Theme) => {
    setTheme(t);
    navigate("homepage");
  };

  const navigate = (t: Tab) => {
    setCurrentTab(t);
  };

  const renderTab = (currentTab: Tab): JSX.Element => {
    switch (currentTab) {
      case "homepage":
        return <HomePage navigate={navigate} />;
      case "theme":
        return <ThemeSelector onThemeSelect={onThemeSelect} />;
      case "past":
        return <PastChallenges navigate={navigate} />;
      case "about":
        return <AboutPage navigate={navigate} />;
      case "badges":
        return <BadgeWorkshopTab />;
      case "fixAnswer":
        return <FixAnswer navigate={navigate} />;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ActiveUserContext.Provider value={userId}>
        <Page>
          {userId ? (
            <>
              <Header
                userId={userId}
                onThemeChange={onThemeChange}
                onLogout={onLogout}
              />
              {renderTab(currentTab)}
              <Footer />
            </>
          ) : (
            <Login onSubmitName={onSubmitName} />
          )}
        </Page>
      </ActiveUserContext.Provider>
    </ThemeProvider>
  );
}

export default App;
