import { styled } from "styled-components";
import { Button, LeaderboardDivider } from "./Common";
import { useEffect, useRef, useState } from "react";

export const CHANGELOG_MESSAGES_NEW = [
  "[6/26/2024] Added a 'Help I Got It Correct' button. Context is that the answer songs are chosen from Spotify, and the search is also using Spotify, but Spotify often has multiple versions of the same song. Now you can manually change one of your guesses to be the correct song, and it will update streaks accordingly. I did set it to log the songs anytime someone makes a manual correction, juuuuust to keep an eye out for any ~nefarious~ activites. Note: you have to submit all 6 guesses/skips before you can make the correction.",
];

export const CHANGELOG_MESSAGES_OLD = [
  "[6/6/2024] Adjusted leaderboard ordering - users should now appear in order according to when they submitted their first guess/skip of the day. The streak tracking messed it up for the past few days.",
  "[6/3/2024] Adjusted streak logic - If nobody gets the song correct, then correct-streaks will not reset (credit to Bigman for the idea).",
  "[6/2/2024] Added streak badges",
  "[5/24/2024] You can now listen to the songs that other people guessed! Click the entry on the leaderboard to load, click again to play, click again to stop.",
  "[5/24/2024] Added Changelog",
];

const ChangelogContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 74px;
  padding: 0px 10px;
`;

const ChangelogContent = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 500px;
`;

const ChangelogMessages = styled.div`
  text-align: left;
`;

const ChangelogButton = styled(Button)`
  margin-top: 20px;
`;

const ChangelogLine = styled.div`
  margin: 10px 0px;
`;

const Changelog = () => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expanded && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [expanded]);

  return (
    <ChangelogContainer>
      <ChangelogContent>
        Changelog
        <LeaderboardDivider />
        <ChangelogMessages>
          {CHANGELOG_MESSAGES_NEW.map((m, index) => (
            <ChangelogLine key={index}>- {m}</ChangelogLine>
          ))}
          {expanded && (
            <>
              <LeaderboardDivider />
              {CHANGELOG_MESSAGES_OLD.map((m, index) => (
                <ChangelogLine key={index}>- {m}</ChangelogLine>
              ))}
              <div ref={scrollRef} />
            </>
          )}
        </ChangelogMessages>
        {expanded ? (
          <ChangelogButton onClick={() => setExpanded(false)}>
            Collapse
          </ChangelogButton>
        ) : (
          <ChangelogButton onClick={() => setExpanded(true)}>
            Expand
          </ChangelogButton>
        )}
      </ChangelogContent>
    </ChangelogContainer>
  );
};

export default Changelog;
