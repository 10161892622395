import styled from "styled-components";
import { SpotifyTrack } from "../models/SpotifyTrack.model";

interface AnswerTrackProps {
  track: SpotifyTrack;
}

const AnswerSection = styled.div`
  margin-top: 20px;
`;

const AnswerImage = styled.img`
  margin-top: 10px;
  width: 200px;
  height: 200px;
  vertical-align: middle;
`;

const AnswerContainer = styled.div`
  background-color: ${(props) => props.theme.SECONDARY};
  height: 90px;
  width: 200px;
  margin: auto;
`;

const AnswerTitle = styled.div`
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 30px;
  margin: 0px 10px;
`;

const AnswerTrack = ({ track }: AnswerTrackProps) => {
  return (
    <AnswerSection>
      <AnswerImage src={track.imageUrlBig} />
      <AnswerContainer>
        <AnswerTitle>{track.name}</AnswerTitle>
        <AnswerTitle>{track.artists.join(", ")}</AnswerTitle>
        <AnswerTitle>{track.album}</AnswerTitle>
      </AnswerContainer>
    </AnswerSection>
  );
};

export default AnswerTrack;
